.regiao .box {
  margin-bottom: 3rem;
  gap: 1rem;
  box-shadow: #23518528 0 1.3rem 2.7rem -0.5rem,
    rgba(0, 0, 0, 0.3) 0 0.8rem 1.6rem -0.8rem;
}

.regiao .images {
  width: 32rem;
  height: 22rem;
}

.regiao img {
  width: 32rem;
  height: 22rem;
  object-fit: cover;
  cursor: pointer;
}

.text {
  width: 90%;
}

.regiao h1 {
  font-size: 2.2rem;
}

.sub .desc p {
  font-size: 1.4rem;
  line-height: normal;

}

.regiao a {
  color: black;
}

.sub label {
  cursor: auto;
}

@media screen and (max-width: 1000px) {
  .regiao .box {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
  }

  .regiao .images,
  .regiao img {
    width: 100%;
  }

  .items .box .text {
    padding: 0 1rem;
  }
}
