main .container {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.mainContent {
  width: 75%;
}

.sideContent {
  width: 23%;
}

@media screen and (max-width: 1000px) {
  main .container {
    flex-direction: column;
  }
  .mainContent {
    width: 100%;
  }

  .sideContent {
    width: 100%;
  }
}
