.tpost .box {
  justify-content: flex-start;
  margin-bottom: 1rem;
  box-shadow: #23518528 0 1.3rem 2.7rem -0.5rem,
    rgba(0, 0, 0, 0.3) 0 0.8rem 1.6rem -0.8rem;
}

.tpost .img {
  width: 16rem;
  height: 8rem;
}

.tpost img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tpost .text {
  padding: 0 1rem;
}

.tpost a {
  color: #000;
}

.tpost h1 {
  font-size: 1.5rem;
}

.tpost span {
  font-size: 1.2rem;
  color: grey;
}

@media screen and (max-width: 1000px) {
  .tpost .img {
    width: 40rem;
    height: 15rem;
  }

  .tpost img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .tpost .text {
    width: 100%;
  }
}
